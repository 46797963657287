import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Button from '../../../components/ui/button';
import Headline from '../../../components/ui/headline';
import Link from '../../../components/ui/link';
import Text from '../../../components/ui/text';
import { useLocale } from '../../../context/locale.context';
import { getCopy } from '../../../helpers/copy';
import Container from '../../../modules/container';
import Layout from '../../../modules/layout';
import * as styles from '../../../styles/newsletter.module.scss';

const NewsletterSubscribeConfirmation = () => {
  const { locale } = useLocale();
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );
  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  return (
    <Layout
      title={getCopy('title.newsletterSubscribeConfirmation', microcopy)}
      description={getCopy('desc.newsletterSubscribeConfirmation', microcopy)}
      slug={'newsletter/subscribe-confirmation'}
    >
      <Container className={styles.newsletterWrapper}>
        <Headline
          level="h1"
          type="h1"
          text={getCopy('newsletter.subscribeConfirmationHeadline', microcopy)}
        />
        <Text as="p" variant="light" type="p1">
          {getCopy('newsletter.subscribeText', microcopy)}
        </Text>
        <Link link={{ linkToPage: 'newsletter/unsubscribe' }}>
          <Button
            text={getCopy('newsletter.unsubscribeButton', microcopy)}
            type={'primary'}
          ></Button>
        </Link>
      </Container>
    </Layout>
  );
};

export default NewsletterSubscribeConfirmation;
